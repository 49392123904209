var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t("cip.dc.EXCELDComission.title2"),
        },
        on: {
          "head-tb": function ($event) {
            return _vm.submitObj()
          },
          "head-back": function ($event) {
            return _vm.toBack()
          },
        },
      }),
      _c(
        "el-collapse",
        {
          staticStyle: { margintop: "10px" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: "1" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "i",
                  {
                    staticClass: "avue-group__title avue-group__header",
                    staticStyle: {
                      color: "#999999 !important",
                      "font-style": "normal",
                      padding: "0 10px",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-document",
                      staticStyle: { "font-size": "20px", padding: "0 10px" },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("cip.dc.ExcelDataImport.btn.SelectFile")
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _c(
                "el-form",
                {
                  ref: "step1Form",
                  attrs: {
                    model: _vm.step1Form,
                    "label-width": "120px",
                    disabled: _vm.$route.query.type == "edit",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.dc.ExcelDataImport.field.PleaseSelectDatabase"
                                ),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "cip.dc.ExcelDataImport.field.PleaseSelectDatabase"
                                    ),
                                    disabled: _vm.listDisabled,
                                  },
                                  model: {
                                    value: _vm.step1Form.dbname,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.step1Form, "dbname", $$v)
                                    },
                                    expression: "step1Form.dbname",
                                  },
                                },
                                _vm._l(_vm.dbConnectionData, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.name,
                                      value: item.name,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.dc.ExcelDataImport.field.ImportFrom"
                                ),
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: { click: _vm._upFile },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "cip.dc.ExcelDataImport.btn.SelectFile"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c("input", {
                                staticStyle: { display: "none" },
                                attrs: {
                                  type: "file",
                                  id: "upfile",
                                  accept: ".xlsx",
                                },
                                on: { change: _vm._upChange },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("cip.dc.ExcelDataImport.field.Surface"),
                      },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "fileTable",
                          staticStyle: { width: "80%" },
                          attrs: {
                            data: _vm.dataSource,
                            "show-header": false,
                            border: "",
                            size: "mini",
                          },
                          on: { select: _vm.onSelectChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "selection",
                              width: "55",
                              disabled: _vm.$route.query.type == "edit",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "name", label: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { name: "2" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "i",
                  {
                    staticClass: "avue-group__title avue-group__header",
                    staticStyle: {
                      color: "#999999 !important",
                      "font-style": "normal",
                      padding: "0 10px",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-document",
                      staticStyle: { "font-size": "20px", padding: "0 10px" },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("cip.dc.ExcelDataImport.btn.SetStartLine")
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _c(
                "el-form",
                {
                  ref: "step2Form",
                  attrs: {
                    model: _vm.step1Form,
                    "label-width": "120px",
                    disabled: _vm.$route.query.type == "edit",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        marginbottom: "10px",
                        marginleft: "25px",
                        color: "#3f4448",
                        "font-size": "16px",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("cip.dc.ExcelDataImport.msg.msg1")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.dc.ExcelDataImport.field.ColumnNameLine"
                                ),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.columnNum,
                                  callback: function ($$v) {
                                    _vm.columnNum = $$v
                                  },
                                  expression: "columnNum",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.dc.ExcelDataImport.field.FirstDataRow"
                                ),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.rowNum,
                                  callback: function ($$v) {
                                    _vm.rowNum = $$v
                                  },
                                  expression: "rowNum",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.dc.ExcelDataImport.field.LastDataRow"
                                ),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.lastrowNum,
                                  callback: function ($$v) {
                                    _vm.lastrowNum = $$v
                                  },
                                  expression: "lastrowNum",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { name: "3" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "i",
                  {
                    staticClass: "avue-group__title avue-group__header",
                    staticStyle: {
                      color: "#999999 !important",
                      "font-style": "normal",
                      padding: "0 10px",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-document",
                      staticStyle: { "font-size": "20px", padding: "0 10px" },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "cip.dc.ExcelDataImport.btn.ConfigureImportTable"
                          )
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _c(
                "el-form",
                {
                  ref: "step3Form",
                  attrs: {
                    model: _vm.step1Form,
                    "label-width": "120px",
                    disabled: _vm.$route.query.type == "edit",
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      staticStyle: { width: "90%", margin: "0 auto" },
                      attrs: {
                        data: _vm.dataSourceThree,
                        "tooltip-effect": "dark",
                        border: "true",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t(
                            "cip.dc.ExcelDataImport.field.SourceTable"
                          ),
                          prop: "name",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "tablename",
                          label: _vm.$t(
                            "cip.dc.ExcelDataImport.field.TargetTable"
                          ),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("el-input", {
                                  model: {
                                    value: row.tablename,
                                    callback: function ($$v) {
                                      _vm.$set(row, "tablename", $$v)
                                    },
                                    expression: "row.tablename",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "isCheck",
                          label: _vm.$t(
                            "cip.dc.ExcelDataImport.field.NewTable"
                          ),
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("el-checkbox", {
                                  model: {
                                    value: row.isCheck,
                                    callback: function ($$v) {
                                      _vm.$set(row, "isCheck", $$v)
                                    },
                                    expression: "row.isCheck",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { name: "4" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "i",
                  {
                    staticClass: "avue-group__title avue-group__header",
                    staticStyle: {
                      color: "#999999 !important",
                      "font-style": "normal",
                      padding: "0 10px",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-document",
                      staticStyle: { "font-size": "20px", padding: "0 10px" },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("cip.dc.ExcelDataImport.btn.SetImportFields")
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _c(
                "el-form",
                {
                  ref: "step4Form",
                  attrs: {
                    model: _vm.step1Form,
                    "label-width": "120px",
                    disabled: _vm.$route.query.type == "edit",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        marginbottom: "10px",
                        marginleft: "25px",
                        color: "#3f4448",
                        "font-size": "16px",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("cip.dc.ExcelDataImport.msg.msg2")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "el-row",
                    { staticStyle: { margin: "10px 0" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: "8" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.dc.ExcelDataImport.field.SourceTable"
                                ),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "200px" },
                                  on: { change: _vm.fourChange },
                                  model: {
                                    value: _vm.currentIndexRef.sourceTable,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.currentIndexRef,
                                        "sourceTable",
                                        $$v
                                      )
                                    },
                                    expression: "currentIndexRef.sourceTable",
                                  },
                                },
                                _vm._l(_vm.dataSourceThree, function (item) {
                                  return _c("el-option", {
                                    key: item.name,
                                    attrs: {
                                      label: item.name,
                                      value: item.name,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.ExcelDataImport.field.TargetTable"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "85%" },
                              attrs: {
                                data: _vm.currentIndexRef.tempdataSourceFours,
                                border: "true",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "column_name",
                                  label: _vm.$t(
                                    "cip.dc.ExcelDataImport.field.TargetField"
                                  ),
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c("el-input", {
                                          model: {
                                            value: row.column_name,
                                            callback: function ($$v) {
                                              _vm.$set(row, "column_name", $$v)
                                            },
                                            expression: "row.column_name",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "column_type",
                                  label: _vm.$t(
                                    "cip.dc.ExcelDataImport.field.Type"
                                  ),
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            model: {
                                              value: row.column_type,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row,
                                                  "column_type",
                                                  $$v
                                                )
                                              },
                                              expression: "row.column_type",
                                            },
                                          },
                                          _vm._l(_vm.typelist, function (item) {
                                            return _c("el-option", {
                                              key: item,
                                              attrs: {
                                                label: item,
                                                value: item,
                                              },
                                            })
                                          }),
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "column_length",
                                  label: _vm.$t(
                                    "cip.dc.ExcelDataImport.field.length"
                                  ),
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c("el-input", {
                                          model: {
                                            value: row.column_length,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                row,
                                                "column_length",
                                                $$v
                                              )
                                            },
                                            expression: "row.column_length",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "column_primaryKey",
                                  label: _vm.$t(
                                    "cip.dc.ExcelDataImport.field.PrimaryKey"
                                  ),
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c("el-checkbox", {
                                          model: {
                                            value: row.column_primaryKey,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                row,
                                                "column_primaryKey",
                                                $$v
                                              )
                                            },
                                            expression: "row.column_primaryKey",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { name: "5" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "i",
                  {
                    staticClass: "avue-group__title avue-group__header",
                    staticStyle: {
                      color: "#999999 !important",
                      "font-style": "normal",
                      padding: "0 10px",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-document",
                      staticStyle: { "font-size": "20px", padding: "0 10px" },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("cip.dc.ExcelDataImport.btn.SetImportMode")
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _c(
                "el-form",
                {
                  ref: "step5Form",
                  attrs: { model: _vm.step1Form, "label-width": "120px" },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.importModel,
                            callback: function ($$v) {
                              _vm.importModel = $$v
                            },
                            expression: "importModel",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v(
                              _vm._s(_vm.$t("cip.dc.ExcelDataImport.msg.msg3"))
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }