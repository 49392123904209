<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t('cip.dc.EXCELDComission.title2')"
      @head-tb="submitObj()"
      @head-back="toBack()"
    ></head-layout>

    <el-collapse v-model="activeName" style="margintop: 10px">
      <el-collapse-item name="1">
        <template slot="title">
          <i
            class="avue-group__title avue-group__header"
            style="
              color: #999999 !important;
              font-style: normal;
              padding: 0 10px;
            "
            ><i
              class="el-icon-document"
              style="font-size: 20px; padding: 0 10px"
            ></i>
            {{ $t("cip.dc.ExcelDataImport.btn.SelectFile") }}
          </i>
        </template>
        <el-form ref="step1Form" :model="step1Form" label-width="120px" :disabled="$route.query.type == 'edit'">
          <el-row>
            <el-col :span="8">
              <el-form-item
                :label="$t('cip.dc.ExcelDataImport.field.PleaseSelectDatabase')"
              >
                <el-select
                  v-model="step1Form.dbname"
                  :placeholder="
                    $t('cip.dc.ExcelDataImport.field.PleaseSelectDatabase')
                  "
                  :disabled="listDisabled"
                >
                  <el-option
                    v-for="item in dbConnectionData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('cip.dc.ExcelDataImport.field.ImportFrom')"
              >
                <el-button size="small" type="primary" @click="_upFile" 
                  >{{ $t("cip.dc.ExcelDataImport.btn.SelectFile") }}</el-button
                >
                <input
                  type="file"
                  id="upfile"
                  style="display: none"
                  accept=".xlsx"
                  @change="_upChange"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item :label="$t('cip.dc.ExcelDataImport.field.Surface')">
            <el-table
              ref="fileTable"
              :data="dataSource"
              :show-header="false"
              border
              @select="onSelectChange"
              size="mini"
              style="width: 80%"
            >
              <el-table-column type="selection" width="55" :disabled="$route.query.type == 'edit'"> </el-table-column>
              <el-table-column prop="name" label=""> </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
      </el-collapse-item>
      <el-collapse-item name="2">
        <template slot="title">
          <i
            class="avue-group__title avue-group__header"
            style="
              color: #999999 !important;
              font-style: normal;
              padding: 0 10px;
            "
            ><i
              class="el-icon-document"
              style="font-size: 20px; padding: 0 10px"
            ></i>
            {{ $t("cip.dc.ExcelDataImport.btn.SetStartLine") }}
          </i>
        </template>
        <el-form ref="step2Form" :model="step1Form" label-width="120px" :disabled="$route.query.type == 'edit'">
          <div
            style="
              marginbottom: 10px;
              marginleft: 25px;
              color: #3f4448;
              font-size: 16px;
            "
          >
            {{ $t("cip.dc.ExcelDataImport.msg.msg1") }}
          </div>
          <el-row>
            <el-col :span="8">
              <el-form-item
                :label="$t('cip.dc.ExcelDataImport.field.ColumnNameLine')"
              >
                <el-input v-model="columnNum" type="text"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('cip.dc.ExcelDataImport.field.FirstDataRow')"
              >
                <el-input v-model="rowNum" type="text"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('cip.dc.ExcelDataImport.field.LastDataRow')"
              >
                <el-input v-model="lastrowNum" type="text"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-collapse-item>

      <el-collapse-item name="3">
        <template slot="title">
          <i
            class="avue-group__title avue-group__header"
            style="
              color: #999999 !important;
              font-style: normal;
              padding: 0 10px;
            "
            ><i
              class="el-icon-document"
              style="font-size: 20px; padding: 0 10px"
            ></i>
            {{ $t("cip.dc.ExcelDataImport.btn.ConfigureImportTable") }}
          </i>
        </template>

        <el-form ref="step3Form" :model="step1Form" label-width="120px" :disabled="$route.query.type == 'edit'">
          <el-table
            ref="multipleTable"
            :data="dataSourceThree"
            tooltip-effect="dark"
            style="width: 90%; margin: 0 auto"
            border="true"
          >
            <el-table-column
              :label="$t('cip.dc.ExcelDataImport.field.SourceTable')"
              prop="name"
            >
            </el-table-column>
            <el-table-column
              prop="tablename"
              :label="$t('cip.dc.ExcelDataImport.field.TargetTable')"
            >
              <template slot-scope="{ row }">
                <el-input v-model="row.tablename" />
              </template>
            </el-table-column>
            <el-table-column
              prop="isCheck"
              :label="$t('cip.dc.ExcelDataImport.field.NewTable')"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <el-checkbox v-model="row.isCheck"></el-checkbox>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-collapse-item>

      <el-collapse-item name="4">
        <template slot="title">
          <i
            class="avue-group__title avue-group__header"
            style="
              color: #999999 !important;
              font-style: normal;
              padding: 0 10px;
            "
            ><i
              class="el-icon-document"
              style="font-size: 20px; padding: 0 10px"
            ></i>
            {{ $t("cip.dc.ExcelDataImport.btn.SetImportFields") }}
          </i>
        </template>

        <el-form ref="step4Form" :model="step1Form" label-width="120px" :disabled="$route.query.type == 'edit'">
          <div
            style="
              marginbottom: 10px;
              marginleft: 25px;
              color: #3f4448;
              font-size: 16px;
            "
          >
            {{ $t("cip.dc.ExcelDataImport.msg.msg2") }}
          </div>
          <el-row style="margin: 10px 0">
            <el-col span="8">
              <el-form-item
                :label="$t('cip.dc.ExcelDataImport.field.SourceTable')"
              >
                <el-select
                  v-model="currentIndexRef.sourceTable"
                  @change="fourChange"
                  style="width: 200px"
                >
                  <el-option
                    v-for="item in dataSourceThree"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item
              :label="$t('cip.dc.ExcelDataImport.field.TargetTable')"
            >
              <el-table
                :data="currentIndexRef.tempdataSourceFours"
                style="width: 85%"
                 border="true"
              >
                <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
                <el-table-column
                  prop="column_name"
                  :label="$t('cip.dc.ExcelDataImport.field.TargetField')"
                >
                  <template slot-scope="{ row }">
                    <el-input v-model="row.column_name" />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="column_type"
                  :label="$t('cip.dc.ExcelDataImport.field.Type')"
                >
                  <template slot-scope="{ row }">
                    <el-select v-model="row.column_type">
                      <el-option
                        v-for="item in typelist"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="column_length"
                  :label="$t('cip.dc.ExcelDataImport.field.length')"
                >
                  <template slot-scope="{ row }">
                    <el-input v-model="row.column_length" />
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  prop="column_decimal"
                  :label="$t('cip.dc.ExcelDataImport.field.Proportion')"
                >
                  <template slot-scope="{ row }">
                    <el-input v-model="row.column_decimal" />
                  </template>
                </el-table-column> -->
                <el-table-column
                  prop="column_primaryKey"
                  :label="$t('cip.dc.ExcelDataImport.field.PrimaryKey')"
                >
                  <template slot-scope="{ row }">
                    <el-checkbox v-model="row.column_primaryKey"></el-checkbox>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-row>
        </el-form>
      </el-collapse-item>

      <el-collapse-item name="5">
        <template slot="title">
          <i
            class="avue-group__title avue-group__header"
            style="
              color: #999999 !important;
              font-style: normal;
              padding: 0 10px;
            "
            ><i
              class="el-icon-document"
              style="font-size: 20px; padding: 0 10px"
            ></i>
            {{ $t("cip.dc.ExcelDataImport.btn.SetImportMode") }}
          </i>
        </template>

        <el-form ref="step5Form" :model="step1Form" label-width="120px">
          <el-form-item>
            <el-radio-group v-model="importModel">
              <el-radio :label="1">{{
                $t("cip.dc.ExcelDataImport.msg.msg3")
              }}</el-radio>
              <!-- <el-radio
                  :label="$t('cip.dc.ExcelDataImport.msg.msg4')"
                  value="2"
                ></el-radio> -->
            </el-radio-group>
          </el-form-item>
        </el-form>
      </el-collapse-item>

      <!-- <el-collapse-item  name="6">
            <template slot="title">
              <i class="avue-group__title avue-group__header" style="color: #999999 !important; font-style: normal;padding: 0 10px;"><i class="el-icon-document" style="font-size: 20px;padding: 0 10px;"></i> {{ $t('cip.dc.ExcelDataImport.btn.StartImporting') }}
              </i>
            </template>

             <el-form ref="step5Form"  :model="step1Form" label-width="120px">

      <el-form-item :label="$t('cip.dc.OffCollection.field.Startsynchronization')"></el-form-item>

      <el-button type="primary" @click="submitObj" style="margin-bottom: 30px">{{$t('cip.dc.OffCollection.field.start')  }}</el-button>


    </el-form>
          
          </el-collapse-item> -->
    </el-collapse>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import {
  dbConnectionList,
  dbConnectionList2,
  importExcelData,
  excelListDetail,
} from "@/api/dataAcquisition/index";

export default {
  components: {
    HeadLayout,
  },

  data() {
    return {
      headBtnOptions: [
        {
          label: this.$t("cip.dc.OffCollection.field.Startsynchronization"),
          emit: "head-tb",
          type: "button",
          icon: "",
          btnOptType: "save",
          loading: false,
          disabled:false,
        },
        {
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-back",
          type: "button",
          icon: "",
          loading: false,
        },
      ],
      currentIndexRef: {
        tempdataSourceFours: [],
        sourceTable: "",
      },
      listDisabled: false,
      typelist: [
        "tinyint",
        "int",
        "bigint",
        "decimal",
        "varchar",
        "char",
        "date",
      ],
      activeName: ["1", "2", "3", "4", "5"],
      dataSource: [],
      dataSourceThree: [],
      tableNameList: [],
      sourceTable: "",
      importModel: 1,

      targetTable: "",
      issource: false,
      stepsActive: 0,
      step1Form: {
        dbname: "",
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      step2Form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      step3Form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      dbConnectionData: [],
      columnNum: 1,
      rowNum: 2,
      lastrowNum: "",
      // importModel: 1,
    };
  },
  computed: {},
  methods: {
    prev() {
      this.stepsActive--;
      if (this.stepsActive < 0) {
        this.stepsActive = 0;
      }
    },

    onSelectChange(selection, row) {
      this.dataSourceThree = selection;
      console.log(this.dataSourceThree, "sdasdasdasd");
      // this.currentIndexRef.sourceTable =  this.dataSourceThree[0].name;
    },
    async getDbConnectionList() {
      const res = await dbConnectionList();
      console.log(JSON.parse(res.data.data), "xxxxxxxx");
      this.dbConnectionData = JSON.parse(res.data.data).filter((item) => {
        return item.dbtype === "Mysql";
      });
      // const {data}=await  AiModelList()
      // console.log(data,'data')
    },

    _upFile() {
      let upimgs = document.getElementById("upfile");
      upimgs.click();
    },
    getKList() {
      dbConnectionList2().then((res) => {
        console.log(JSON.parse(res.data.data));
        this.dbConnectionData = JSON.parse(res.data.data).filter((item) => {
          return item.dbtype === "Mysql";
        });
        if (this.dbConnectionData.length) {
          this.step1Form.dbname = this.dbConnectionData[0].name.toString();
          this.listDisabled = true;
        }
      });
    },
    _upChange(e) {
      let file = e.target.files[0]; //获取第一个文件
      let reader = new FileReader();
      const _that = this;
      reader.readAsBinaryString(file); //读取这个文件
      reader.onload = function (event) {
        _that.currentIndexRef.tempdataSourceFours = [];
        _that.currentIndexRef.sourceTable = "";
        let result = event.target.result;
        let xlsxdata = XLSX.read(result, { type: "binary" }); //读取xlsx
        let sheetlist = [];
        for (let n in xlsxdata.Sheets) {
          //这里是多张表格 所以写一个循环
          let col = XLSX.utils.sheet_to_json(xlsxdata.Sheets[n], {
            header: 1,
            defval: "",
            blankrows: true,
          }); //解析为数组
          // console.log(col)
          let lan = col[_that.columnNum - 1];
          let startzhi = col[_that.rowNum - 1];
          let newobj = {
            name: n,
            val: col,
            tablename: n,
            isCheck: true,
            lan: lan,
            startzhi: startzhi,
            endzhi: "",
            columnNum: _that.columnNum,
            rowNum: _that.rowNum,
            lastrowNum: _that.lastrowNum,
            importModel: 1,
            ISNew: false,
          };
          sheetlist.push(newobj);
        }
        _that.dataSource = sheetlist;
      };
    },
    fourChange(value) {
      this.dataSourceThree.forEach((item, index) => {
        if (item.name == value) {
          this.sourceTable = item.name;
          this.currentIndexRef.sourceTable = value;
          // console.log(item.isCheck,item.ISNew)
          if (item.isCheck) {
            if (item.sourcesave && item.sourcesave.length > 0) {
              item.Nydata = item.sourcesave;
              this.currentIndexRef.tempdataSourceFours = item.sourcesave;
            } else {
              let lanlist = item.lan;

              let objlst = [];
              for (let o = 0; o < lanlist.length; o++) {
                let newobj = {
                  column_name: lanlist[o],
                  column_type: "varchar",
                  column_length: 255,
                  column_decimal: 0,
                  column_primaryKey: false,
                  isimport: true,
                  id: Math.random().toString(36).substr(2).toUpperCase(),
                };
                objlst.push(newobj);
              }
              // settempdataSourceFour(objlst);
              item.Nydata = objlst;
              item.sourcesave = objlst;

              this.currentIndexRef.tempdataSourceFours = objlst;
            }
          } else {
            if (item.sourcesave && item.sourcesave.length > 0) {
              item.Nydata = item.sourcesave;
              this.currentIndexRef.tempdataSourceFours = item.sourcesave;
            } else {
              let lanlist = item.columnlist;
              let objlst2 = [];
              for (let o = 0; o < lanlist.length; o++) {
                let newobj = {
                  column_name: lanlist[o],
                  sourcename: "",
                  isprimarykey2: false,
                  sourcelan: item.lan,
                };
                objlst2.push(newobj);
              }
              item.Nydata = objlst2;
              item.sourcesave = objlst2;
              this.currentIndexRef.tempdataSourceFours = objlst2;
            }
          }
        }
      });
    },
    submitObj() {
      importExcelData({
        columnNum: this.columnNum,
        rowNum: this.rowNum,
        lastrowNum: this.lastrowNum,
        dbname: this.step1Form.dbname,
        data: this.dataSourceThree,
      }).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getDetail(id) {
      excelListDetail({ id: id }).then((res) => {
        let data = res.data.data;
        this.dataSource.push({ name: data.excelName });
        this.$refs.fileTable.toggleRowSelection(this.dataSource[0]);
        this.columnNum = data.columnNum;
        this.rowNum = data.rowNum;
        this.lastrowNum = data.lastrowNum;

        var index = data.tableName.indexOf("_");
        var resolve = data.tableName.substring(0, index);
        this.dataSourceThree = [
          { name: data.excelName, tablename: resolve, isCheck: true },
        ];


        this.currentIndexRef.sourceTable = data.excelName
        data.columnList.forEach(element => {
          element.column_name = element.columnName
          element.column_type = element.dataType
          element.column_length = element.dataLength
          // element.column_decimal = element.columnName
          element.column_primaryKey = JSON.parse(element.columnKey) 
        });
        this.currentIndexRef.tempdataSourceFours = data.columnList

        // column_name  column_type column_length column_decimal column_primaryKey
      });
    },
    toBack(){
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    }
  },
  created() {
    // this.getDbConnectionList()
    this.getKList();
    if (this.$route.query.type == "edit") {
      this.getDetail(this.$route.query.id);
      this.headBtnOptions[0].disabled = true
    }
  },
  mounted() {
    this.importModel = 1;
  },
};
</script>
<style>
</style>
